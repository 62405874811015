@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply text-default bg-background-lite dark:bg-background-dark;
    }

    a {
        @apply text-primary-lite-500 dark:text-primary-dark-300 font-normal;
    }

    p {
        @apply mb-4;
    }

    button {
        @apply btn;
    }

    button[disabled] {
        @apply btn-disabled;
    }

    button.btn-outline[disabled] {
        @apply btn-outline-disabled;
    }

    input {
        @apply input;
    }
}

@layer components {
    .pane {
        @apply p-6 md:p-8 rounded-xl bg-pane-lite dark:bg-pane-dark border w-full shadow shadow-lg
    }

    .bubble {
        @apply p-3 md:p-4 rounded-2xl border border-primary-lite-100 dark:border-primary-dark-100 bg-primary-lite-100 dark:bg-primary-dark-500 w-full
    }

    .card {
        @apply bg-card-lite dark:bg-card-dark rounded-xl p-6 shadow shadow-xl
    }

    .token-logo {
        @apply rounded-full w-8 h-8
    }
}

@layer utilities {
    .content {
        @apply w-full md:w-128 mx-auto
    }

    .text-default {
        @apply text-on-primary-lite dark:text-on-primary-dark font-normal;
    }

    .title {
        @apply text-2xl md:text-3xl leading-snug md:leading-snug font-baloo-bhai-2 font-bold text-on-primary-lite dark:text-on-primary-dark
    }

    .subtitle {
        @apply text-xl md:text-2xl leading-snug md:leading-snug font-bold text-on-primary-lite dark:text-on-primary-dark
    }

    .heading {
        @apply text-base md:text-lg leading-snug md:leading-snug font-medium text-black dark:text-white
    }

    .body {
        @apply text-sm md:text-base leading-snug md:leading-snug font-light
    }

    .quote {
        @apply text-sm md:text-base leading-snug md:leading-snug font-medium text-primary-lite-500 dark:text-primary-dark-500
    }

    .caption {
        @apply text-xs md:text-sm leading-snug md:leading-snug font-normal
    }

    .note {
        @apply text-xs md:text-sm leading-snug md:leading-snug font-light text-gray-500 dark:text-gray-500
    }

    .btn {
        @apply px-4 py-2 rounded-2xl hover:backdrop-contrast-100 border border-primary-lite-400 dark:border-primary-dark-400 bg-primary-lite-400 dark:bg-primary-dark-400 flex items-center justify-center text-sm md:text-base text-white
    }

    .btn-disabled {
        @apply border-primary-lite-300 dark:border-primary-dark-300 bg-primary-lite-300 dark:bg-primary-dark-300 text-primary-lite-200 dark:text-primary-dark-200 cursor-not-allowed
    }

    .btn-lg {
        @apply rounded-lg text-lg py-4 px-6
    }

    .btn-outline {
        @apply bg-transparent dark:bg-transparent text-primary-lite-500 dark:text-white dark:border-white
    }

    .btn-outline-disabled {
        @apply bg-gray-100 text-primary-lite-300 dark:text-primary-dark-300
    }

    .btn-clear {
        @apply bg-transparent text-primary-lite-500 dark:text-primary-dark-500 border-none hover:backdrop-contrast-75
    }

    .btn-twitter {
        @apply border-twitter bg-twitter text-white
    }

    .btn-discord {
        @apply border-discord bg-discord text-white
    }

    .input {
        @apply py-2 rounded-2xl px-3 flex items-center text-sm md:text-base border border-primary-lite-500 dark:border-primary-dark-500
    }

    .select {
        @apply appearance-none block py-3 px-4 w-full rounded bg-secondary-lite dark:bg-secondary-dark border border-2 border-white text-lg text-white bg-blend-screen
    }

    .select-lg {
        @apply rounded-lg text-2xl py-4 px-6 border-4
    }

    .web3modal-modal-lightbox {
        z-index: 100 !important;
    }
}
